<template>
  <div class="directory-page content">
    <h1>Directory</h1>
    <div class="department" v-for="department in directory" :key="department.id">
      <h2>{{ department.name }}</h2>
      <div class="contact" v-for="contact in department.contacts" :key="contact.id">
        <div class="contact--name">{{ contact.name }}</div>
        <div class="contact--email">
          <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
        </div>
        <div v-if="contact.subContacts" class="contact--subcontacts">
          <div class="subcontact" v-for="(subcontact, index) in contact.subContacts" :key="index">
            <div class="subcontact--name">{{ subcontact.name }}</div>
            <div class="subcontact--email">
              <a :href="`mailto:${subcontact.email}`">{{ subcontact.email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      directory: [],
    };
  },
  created() {
    this.getDirectory();
  },
  methods: {
    getDirectory() {
      axios.get(`${process.env.VUE_APP_API_URL}/directory/`).then((response) => {
        response.data.data.contacts.forEach((contactData) => {
          let departmentIndex = this.directory.findIndex(
            (department) => department.id === contactData.directoryCategory.id,
          );
          if (departmentIndex === -1) {
            this.directory.push(contactData.directoryCategory);
            departmentIndex = this.directory.length - 1;
            this.directory[departmentIndex].contacts = [];
          }
          // If there is a description, there will be subContacts
          if (contactData.description !== '') {
            let contactIndex = this.directory[departmentIndex].contacts.findIndex(
              (contact) => contact.name.toLowerCase() === contactData.name.toLowerCase(),
            );
            if (contactIndex === -1) {
              const mainContact = {};
              mainContact.id = contactData.id;
              mainContact.name = contactData.name;
              mainContact.subContacts = [];
              this.directory[departmentIndex].contacts.push(mainContact);
              contactIndex = this.directory[departmentIndex].contacts.length - 1;
            }
            const subContact = {};
            subContact.name = contactData.description;
            subContact.email = contactData.email;
            this.directory[departmentIndex].contacts[contactIndex].subContacts.push(subContact);
          } else {
            this.directory[departmentIndex].contacts.push(contactData);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.directory-page {
  h1 {
    margin-bottom: 1.875rem; // 30px
  }
  h2 {
    margin-bottom: 1.875rem; // 30px
    font-size: 0.875rem; // 14px
    font-weight: bold;
  }
  .department {
    margin-bottom: 1.875rem; // 30px
    border-bottom: 1px solid $grey--light;
  }
  .contact {
    margin-bottom: 2.5rem; // 40px
    &--name {
      margin-bottom: 0.9375rem; // 15px
      font-family: $extended;
      font-size: 0.75rem; // 12px
    }
    &--email,
    .subcontact--email {
      font-family: $wide;
      font-size: 0.75rem; // 12px
      font-weight: bold;
      a {
        color: $grey--dark;
        text-decoration: none;
      }
    }
  }
  .subcontact {
    margin-bottom: 1.875rem; // 30px
    &--name {
      margin-bottom: 0.9375rem; // 15px
      font-size: 0.75rem; // 12px
    }
  }
}
</style>
