<template>
  <div class="content feedback-page">
    <h1 v-if="!isCms">Feedback</h1>
    <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
      dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
    </p>
    <form autocomplete="off" method="post" v-on:submit.prevent="submitForm">
      <div class="form-group" :class="{ 'has-error': errors.firstName }">
        <label for="first-name" class="form-label">First Name<sup>*</sup></label>
        <input
          v-model="formData.firstName"
          type="text"
          class="form-control"
          id="first-name"
          placeholder="First Name"
          :class="{ 'is-invalid': errors.firstName }"
        />
        <template v-if="errors.firstName">
          <div v-for="message in errors.firstName.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.lastName }">
        <label for="last-name" class="form-label">Last Name<sup>*</sup></label>
        <input
          v-model="formData.lastName"
          type="text"
          class="form-control"
          id="last-name"
          placeholder="Last Name"
          :class="{ 'is-invalid': errors.lastName }"
        />
        <template v-if="errors.lastName">
          <div v-for="message in errors.lastName.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.accessAudiId }">
        <label for="accessAudiId" class="form-label">Access Audi ID<sup>*</sup></label>
        <input
          v-model="formData.accessAudiId"
          type="text"
          class="form-control"
          id="accessAudiId"
          placeholder="Access Audi ID"
          :class="{ 'is-invalid': errors.accessAudiId }"
          readonly
        />
        <template v-if="errors.accessAudiId">
          <div v-for="message in errors.accessAudiId.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.email }">
        <label for="email" class="form-label">Email<sup>*</sup></label>
        <input
          v-model="formData.email"
          type="email"
          class="form-control"
          id="email"
          placeholder="Email"
          :class="{ 'is-invalid': errors.email }"
        />
        <template v-if="errors.email">
          <div v-for="message in errors.email.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.phone }">
        <label for="phone" class="form-label">Phone<sup>*</sup></label>
        <input
          v-model="formData.phone"
          type="text"
          class="form-control"
          id="phone"
          placeholder="Phone"
          :class="{ 'is-invalid': errors.phone }"
        />
        <template v-if="errors.phone">
          <div v-for="message in errors.phone.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.preferredContact }">
        <label class="form-label">Preferred Contact Method<sup>*</sup></label>
        <div class="checkboxes" :class="{ 'is-invalid': errors.preferredContact }">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="preferred-contact-phone"
              value="Phone"
              v-model="formData.preferredContact"
            />
            <label class="form-check-label" for="preferred-contact-phone">Phone</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="preferred-contact-email"
              value="Email"
              v-model="formData.preferredContact"
            />
            <label class="form-check-label" for="preferred-contact-email">Email</label>
          </div>
        </div>
        <template v-if="errors.preferredContact">
          <div v-for="message in errors.preferredContact.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.department }">
        <label for="department" class="form-label">Subject<sup>*</sup></label>
        <BaseDropdown
          id="department"
          :btnText="formData.department"
          :dropdownOptions="availableDepartments"
          :btnPlaceholder="'Select a department'"
          @selection-clicked="updateDepartment($event)"
          :class="{ 'is-invalid': errors.department }"
        />
        <template v-if="errors.department">
          <div v-for="message in errors.department.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <div class="form-group" :class="{ 'has-error': errors.message }">
        <label for="message" class="form-label">Message<sup>*</sup></label>
        <textarea
          v-model="formData.message"
          type="text"
          class="form-control"
          id="message"
          placeholder="Type your message here..."
          rows="5"
          :class="{ 'is-invalid': errors.message }"
        ></textarea>
        <template v-if="errors.message">
          <div v-for="message in errors.message.messages" :key="message" class="invalid-feedback">
            {{ message }}
          </div>
        </template>
      </div>
      <button class="btn btn-primary" type="submit">Submit Feedback</button>
    </form>
    <div class="toast-container">
      <BaseToast v-if="toastMessage.isShown" :toast="toastMessage" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import BaseDropdown from '@/components/BaseDropdown.vue';
import BaseToast from '@/components/BaseToast.vue';

function initialFormData() {
  return {
    firstName: '',
    lastName: '',
    accessAudiId: '',
    email: '',
    phone: '',
    preferredContact: [],
    department: '',
    departmentId: null,
    message: '',
  };
}

export default {
  components: {
    BaseDropdown,
    BaseToast,
  },
  data() {
    return {
      formData: initialFormData(),
      toastMessage: {
        status: 'danger',
        message: '',
        isShown: false,
      },
      errors: {},
    };
  },
  computed: {
    ...mapGetters(['config', 'user']),
    availableDepartments() {
      const availableDepartments = [];
      this.config.feedbackDepartments.forEach((departmentData) => {
        const department = {};
        department.name = departmentData.name;
        department.value = departmentData.id;
        availableDepartments.push(department);
      });
      return availableDepartments;
    },
    isCms() {
      return this.$route.path.includes('/cms/');
    },
  },
  mounted() {
    if (this.user) {
      this.formData.firstName = this.user.firstName;
      this.formData.lastName = this.user.lastName;
      this.formData.accessAudiId = this.user.uid;
      this.formData.email = this.user.email;
      this.formData.phone = this.user.phoneNumber;
    }
  },
  methods: {
    updateDepartment(event) {
      // eslint-disable-next-line
      console.log(event.name, event.value);
      this.formData.department = event.name;
      this.formData.departmentId = event.value;
    },
    submitForm() {
      if (this.validateForm()) {
        const formData = {};
        Object.keys(this.formData).forEach((key) => {
          if (typeof this.formData[key] === 'boolean') {
            formData[key] = this.formData[key] ? 1 : 0;
          } else {
            formData[key] = this.formData[key];
          }
        });
        axios
          .post(`${process.env.VUE_APP_API_URL}/feedback`, formData)
          .then(() => {
            this.resetForm();
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            this.toastMessage.status = 'success';
            this.toastMessage.message = 'Your feedback has been submitted successfully.';
            this.toastMessage.isShown = true;
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error.response);
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
            this.toastMessage.status = 'danger';
            this.toastMessage.message = 'There was an error submitting the form. Please try again.';
            this.toastMessage.isShown = true;
          });
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.toastMessage.status = 'danger';
        this.toastMessage.message = 'Please complete filling out the form.';
        this.toastMessage.isShown = true;
      }
    },
    validateForm() {
      this.errors = {};
      if (this.formData.firstName === '') {
        this.errors.firstName = {};
        this.errors.firstName.messages = [];
        this.errors.firstName.messages.push('Please enter a first name.');
      }
      if (this.formData.lastName === '') {
        this.errors.lastName = {};
        this.errors.lastName.messages = [];
        this.errors.lastName.messages.push('Please enter a last name.');
      }
      if (this.formData.accessAudiId === '') {
        this.errors.accessAudiId = {};
        this.errors.accessAudiId.messages = [];
        this.errors.accessAudiId.messages.push('Please enter an Access Audi ID.');
      }
      if (this.formData.email === '') {
        this.errors.email = {};
        this.errors.email.messages = [];
        this.errors.email.messages.push('Please enter an email address.');
      }
      if (this.formData.phone === '') {
        this.errors.phone = {};
        this.errors.phone.messages = [];
        this.errors.phone.messages.push('Please enter a phone number.');
      }
      if (this.formData.preferredContact.length === 0) {
        this.errors.preferredContact = {};
        this.errors.preferredContact.messages = [];
        this.errors.preferredContact.messages.push('Please specify a preferred contact method.');
      }
      if (this.formData.department === '') {
        this.errors.department = {};
        this.errors.department.messages = [];
        this.errors.department.messages.push('Please specify a department.');
      }
      if (this.formData.message === '') {
        this.errors.message = {};
        this.errors.message.messages = [];
        this.errors.message.messages.push('Please enter a message.');
      }
      return Object.keys(this.errors).length === 0;
    },
    resetForm() {
      Object.assign(this.formData, initialFormData());
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-page {
  position: relative;
  p {
    max-width: 730px;
  }
}
form {
  max-width: 600px;
  ::v-deep .dropdown button {
    width: 100%;
    max-width: none;
  }
  .form-check {
    display: inline-block;
    margin-right: 1rem;
  }
  > .btn {
    width: 100%;
    @include bp-md-tablet {
      width: auto;
    }
  }
}
</style>
